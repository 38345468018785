.__dbk__child-wrapper {
  overflow: hidden;
}

.__dbk__gutter.Vertical {
  /* background: none !important; */
  z-index: 100;
}

.__dbk__gutter.Vertical.is-resizing {
  padding: 0 !important;

}

.__dbk__dragger.Vertical {
  background: none !important;
  margin: -10px 0;
  padding: 12px 0;
  height: 0;
  width: 100% !important;
  z-index: 10001;
}

.is-resizing>.__dbk__dragger.Vertical {
  margin: -200px 0;
  padding: 204px 0;

}

.dark .__dbk__gutter {
  background: var(--dark-border);
}

.light .__dbk__gutter {
  background: var(--light-border);
}

.resize-content-dark {
  background: var(--dark-background);
}

.resize-content-light {
  background: var(--light-background);
}