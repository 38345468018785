.json-editor .card.card-body.bg-light {
    background-color: unset !important;
}

/* regular input  */

.dark .json-editor input:not(.form-check-input) {
    background-color: unset !important;
    border-color: var(--dark-border);
    color: var(--dark-font) !important;
}

.light .json-editor input:not(.form-check-input) {
    background-color: unset !important;
    border-color: var(--light-border);
    color: var(--light-font) !important;
}

.dark .json-editor input.form-check-input {
    background-color: rgba(255, 255, 255, 0.25);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    border-color: var(--dark-border);
}

.light .json-editor input.form-check-input {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: var(--light-border);
}

.dark .json-editor input.form-check-input:checked {
    background-color: var(--dark-fontActive);
}

.light .json-editor input.form-check-input:checked {
    background-color: var(--light-fontActive);
}

/* single dropdown */

.json-editor select {
    background: unset !important;
}

.dark .json-editor select {
    color: var(--dark-font);
    border-color: var(--dark-border);
}

.light .json-editor select {
    color: var(--light-font);
    border-color: var(--light-border);
}

.dark .json-editor select option {
    color: var(--dark-font);
    background-color: var(--dark-background);
}

.light .json-editor select option {
    color: var(--light-font);
    background-color: var(--light-background);
}

/* multi select  */

.json-editor .select2-selection.select2-selection--multiple {
    background-color: unset;
    border-color: var(--dark-border) !important;
}

.dark .json-editor li.select2-selection__choice {
    border-color: var(--dark-border) !important;
    background-color: unset !important;
    color: var(--dark-font) !important;
}

.light .json-editor li.select2-selection__choice {
    border-color: var(--light-border) !important;
    background-color: unset !important;
    color: var(--light-font) !important;
}

.json-editor button.select2-selection__choice__remove {
    border-right: unset !important;
    background-color: unset !important;
}

.dark .json-editor button.select2-selection__choice__remove {
    color: var(--dark-border) !important;
}

.light .json-editor button.select2-selection__choice__remove {
    color: var(--light-border) !important;
}

.dark .json-editor button.select2-selection__choice__remove>span:hover {
    color: var(--dark-font);
}

.light .json-editor button.select2-selection__choice__remove>span:hover {
    color: var(--dark-font);
}

.dark .select2-container .select2-results>ul {
    background: var(--dark-background);
}

.dark .select2-container .select2-results__option--selected {
    background-color: var(--dark-background);
    color: var(--dark-fontActive) !important;
}

.dark .select2-container .select2-results__option--selectable {
    background-color: var(--dark-background);
    color: var(--dark-font);
}

.dark .select2-container .select2-results__option.select2-results__option--highlighted {
    background: var(--dark-backgroundActive);
    color: var(--dark-fontActive) !important;
}

.light .select2-container .select2-results>ul {
    background: var(--light-background);
}

.light .select2-container .select2-results__option--selected {
    background-color: var(--light-background);
    color: var(--light-fontActive) !important;
}

.light .select2-container .select2-results__option--selectable {
    background-color: var(--light-background);
    color: var(--light-font);
}

.light .select2-container .select2-results__option.select2-results__option--highlighted {
    background: var(--light-backgroundActive);
    color: var(--light-fontActive) !important;
}

.light .select2-dropdown {
    border-color: var(--light-border);
}

.dark .select2-dropdown {
    border-color: var(--dark-border);
}

.dark .form-check-label::before {
    background-color: unset;
    border: var(--dark-border) solid 1px;
}


.light .w-100 {
    color: var(--light-font);
}

.light .form-check-label::before {
    background-color: unset;
    border: var(--light-border) solid 1px;
}

.light .select2-container--default .select2-selection--multiple {
    background-color: unset;
    border: var(--light-border) solid 1px;
}

.dark .select2-container--default .select2-selection--multiple {
    background-color: unset;
    border: var(--dark-border) solid 1px;
}

.dark .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: var(--dark-border) 1px solid;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.light .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: var(--light-border) 1px solid;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.dark small.form-text {
    color: var(--dark-font);
}

.light small.form-text {
    color: var(--light-font);
}

.dark .json-editor-btn-collapse.json-editor-btntype-toggle {
    color: var(--dark-font);
    background: var(--dark-fontActive);
    border-color: var(--dark-fontActive);
}

.light .json-editor-btn-collapse.json-editor-btntype-toggle {
    color: var(--light-font);
    background: var(--light-fontActive);
    border-color: var(--light-fontActive);
}