:root {
    --dark-background: #131722;
    --dark-backgroundSecondary:#ffffff0a;
    --dark-backgroundHover: #2a2e39;
    --dark-backgroundActive: #111a2c;
    --dark-font: #b2b5be;
    --dark-fontHover: #fff;
    --dark-fontActive: rgb(108 147 255);
    --dark-border: #2a2e39;
    --dark-borderActive: rgb(108 147 255);
    --dark-borderGlow:#1d1d5f;
    --dark-warning: #fb3;
    --dark-success: #00c851;

    --dark-candles-wick-green: rgb(178, 235, 242);
    --dark-candles-wick-red: rgb(103, 58, 183);
    --dark-candles-body-green: rgb(178, 235, 242);
    --dark-candles-body-red: rgb(103, 58, 183);
    --dark-candles-border-green: rgb(178, 235, 242);
    --dark-candles-border-red: rgb(103, 58, 183);
    

    --light-background: rgb(243 243 243);
    --light-backgroundSecondary: #00000005;
    --light-backgroundHover: #0000000f;
    --light-backgroundActive: #e6f4ff;
    --light-font: #000;
    --light-fontHover: #000;
    --light-fontActive: #1e53e5;
    --light-border: #0000002B;
    --light-borderActive: #1e53e5;
    --light-borderGlow:#1d1d5f;
    --light-warning: #fb3;
    --light-success: #00c851;

    --light-candles-wick-green: rgb(178, 235, 242);
    --light-candles-wick-red: rgb(103, 58, 183);
    --light-candles-body-green: rgb(178, 235, 242);
    --light-candles-body-red: rgb(103, 58, 183);
    --light-candles-border-green: rgb(178, 235, 242);
    --light-candles-border-red: rgb(103, 58, 183);

}

body,
html,
#root {
    width: 100%;
    height: 100%;
}
html,
body {
    overscroll-behavior-y: contain;
    overflow: hidden;
}

/* remove with mui tabs */

.Mui-disabled.MuiTabs-scrollButtons {
    display: none !important;
  }

/* TODO remove */
.alert.alert-danger {
    display: none
}


.ant-modal-close {
    width: 40px !important;
    height: 40px !important;
}